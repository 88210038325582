.images {
  height: auto;
  /* min-height: 40px; */
  /* max-height: 80px; */
  width: auto;
  /* max-width: 150px; */
}

.hover:hover{
  opacity:.6;
  cursor: pointer;
}

.scroll{
  overflow-x: hidden;
  overflow-y: auto;
  min-height:450px;
  max-height:450px;
}

.scroll::-webkit-scrollbar {
  width: 8px;               /* width of the entire scrollbar */
}

.scroll::-webkit-scrollbar-track {
  background: #111;        /* color of the tracking area */
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #333;    /* color of the scroll thumb */
  border-radius: 10px;       /* roundness of the scroll thumb */
  border: 1px solid #333; 
  /* creates padding around scroll thumb */
}


/* .cuttext {
  background: -webkit-linear-gradient(90deg, #000,#aaa);
	background: linear-gradient(90deg,#000, #aaa);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
} */

/* .trash2:hover{opacity:.7;cursor: pointer;}
.exlink:hover{opacity:.7}
.btn-dropdown > details > summary{background-color: '#1d91c0';} */