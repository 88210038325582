@font-face {
  font-family: 'Roboto';
  src:  url('./Roboto/Roboto-Regular.ttf?v=1') format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src:  url('./Roboto/Roboto-Bold.ttf?v=1') format("opentype");
  font-weight: bold;
  font-style: normal;
}

/* @font-face {
  font-family: 'Arsenal';
  src:  url('./fonts/Arsenal-Regular.otf?v=1') format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Arsenal';
  src:  url('./fonts/Arsenal-Bold.otf?v=1') format("opentype");
  font-weight: bold;
  font-style: normal;
} */


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Ukraine"; */
  /* font-family: "Arsenal"; */
  font-family: 'Roboto', sans-serif;
  color:'#333'
}





/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
